import { BaseModel } from "src/app/_models";

export class User extends BaseModel{
    
    id: number;
    name: string;
    email: string;
    phone: string;
    rewards: number;
    status: string;
    photo_id: string | null;
    oauth_uid: string | null;
    email_verified_at: Date | null;
    verified: number;
    created_at?: string;  
    updated_at?: string; 
    deleted_at?: string | null;
    token? : string;
    password? : string;
    keepMeSignedIn? : boolean;
    reset_code? : string;

    constructor(data: any) { 
        super(data);
    }

    fromJson(json: Partial<User>): User {
        Object.assign(this, json);
        return this;
    }

}

export interface Kitchen { }

export interface ApiResponse {
    statusCode: number;
    message: string;
    data: {
        user: User;
        kitchen: Kitchen | null;
        token: string;
    };
}

