import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";

import { environment } from "@environments/environment";
import { BaseModel } from "src/app/_models";
import { User} from '@pages/auth/_models';

@Injectable({ providedIn: "root" })
export class AuthService {
  private $currentUserSubject: BehaviorSubject<User | undefined>;
  
 
  constructor(private http: HttpClient) {
    let userData;
    let currentUserData = localStorage.getItem("currentUser");
    if(currentUserData !== null){
        userData = JSON.parse(currentUserData);
    }
    this.$currentUserSubject = new BehaviorSubject<User | undefined>(
      userData ?   userData : undefined
    );
  }

  public get currentUser(): User | undefined {
    return this.$currentUserSubject.getValue();
  }

  public get currentUserSubject(): BehaviorSubject<User | undefined> {
    return this.$currentUserSubject;
  }

  public setCurrentUser(user: User | undefined) {
    localStorage.setItem("currentUser", JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  register(data: User): Observable<User> {

    delete data.keepMeSignedIn;

    return this.http
      .post<any>(`${environment.apiUrl}/auth/register`, data)
      .pipe(
        map((data) => {
          data = data.data;
          data.user = BaseModel.camelizeKeys(data.user);
          if (data && data.token) {
            data.user = new User(data.user);
            this.setCurrentUser(data.user);
            return data.user;
          }
        })
      );
  }

  reset(data: User): Observable<User> {
    return this.http
      .post<any>(`${environment.apiUrl}/auth/reset-password`, data)
      .pipe(
        map((data) => {
          data = data.data;
            return data;
        })
      );
  }

  
  signin(data: User): Observable<User> {

    delete data.keepMeSignedIn;

    return this.http
      .post<any>(`${environment.apiUrl}/auth/login`, data)
      .pipe(
        map((data) => {
          data = data.data;
          data.user = BaseModel.camelizeKeys(data.user);
          if (data && data.token) {
            data.user = new User(data.user);
            this.setCurrentUser(data.user);
            return data.user;
          }
        })
      );
  }

  signout(): Observable<any> {
    console.log('signout()');
    return this.http
      .post<any>(`${environment.apiUrl}/auth/logout`,{})
      .pipe(
        map((data) => {
          this.currentUserSubject.next(null as any);
          return data;
        }),
        catchError((err, caught) => {
          localStorage.removeItem("currentUser");
          this.currentUserSubject.next(null as any);
          return err;
        })
      );
  }

  verifyEmailOtp(data: any): Observable<User> {
    console.log('VerifiedFunction Called()');
    return this.http
      .post<any>(`${environment.apiUrl}/user/verifyemailotp`, BaseModel.snakeKeys(data))
      .pipe(
        map((data) => {
          data.user = BaseModel.camelizeKeys(data.user);
          if (data && data.user.token) {
            this.setCurrentUser(data.user);
            return data.user;
          } 
        })
      );
  }

  checkOTP(data: any): Observable<User> {
    return this.http
      .post<any>(`${environment.apiUrl}/auth/reset-password/check-otp`, data)
      .pipe(
        map((data) => {
            return data;
        })
      );
  }

  

  updatePassword(data: any): Observable<User> {
    return this.http
      .post<any>(`${environment.apiUrl}/auth/reset-password/confirm`, BaseModel.snakeKeys(data))
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  
}
