<div class="container-fluid d-flex justify-content-center align-items-center flex-column _login_page" style="background-image: url('assets/images/login/auth-background.png');">
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div class="logo">
                <img src="assets/images/logo.svg" alt="Logo" height="auto">
                <p class="mt-3 font-2 display-15 color-black"> SELLER VERSIONS </p>
            </div>
            <div class="_action mt-5">
                <a class="btn py-3 _primary_button font-2 display-16 clor-white w-100" (click)="onClickLogin()">I already have an account</a>
            </div>
            <div class="_action mt-3">
                <a class="btn py-3 _primary_button font-2 display-16 clor-white w-100" (click)="onClickRegister()">Sign up as a new kitchen</a>
            </div>
            <div class="downloads mt-5">
                <label class="font-1 display-15 color-black"> Download SMN app now! </label>
            </div>
            <div class="download_button mt-2">
                <img src="assets/images/login/buttons.svg" alt="Google Play">
            </div>
        </div>
    
    </div>


    <p class="mt-3 font-2 display-15 color-black">2024&#64;All rights reserved to technologies.ae</p>
</div>